<script setup>
import rpBenefitsDesktop from '@/assets/images/statics/rp-benefits-desktop.png';
import rpBenefitsMobile from '@/assets/images/statics/rp-benefits-mobile.png';
useHead({
  title: "Program poleceń - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: `https://vehis.pl${useRoute().fullPath}`}
  ]

});

const backend = useBackend();

const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))
const faqData =  [
  {
    question: "Jak obliczany jest 1% premii?",
    answer:
      "Premię 1% obliczamy od wartości udzielonego finansowania, czyli wartości leasingowanego samochodu pomniejszonej o opłatę wstępną wpłaconą przez klienta.<br/><br/>Przykład: Klient podpisuje umowę leasingową na samochód o wartości 200 000 zł i wpłaca 25% opłaty wstępnej. Wartość finansowania wynosi zatem 150 000 zł, a Ty otrzymujesz 1 500 zł wynagrodzenia.",
  },
  {
    question: "Czy ilość umów leasingowych z mojego polecenia jest ograniczona?",
    answer:
      "Możesz polecać VEHIS bez limitu, a my wypłacimy wynagrodzenie za każde skuteczne polecenie. Tylko od Ciebie zależy, ile zarobisz dzięki współpracy z nami.",
  },
  {
    question: "Kiedy Twoje polecenie będzie skuteczne?",
    answer:
      "Gdy klient podpisze umowę leasingową i wpłaci ustaloną opłatę wstępną.",
  },
  {
    question: "W jaki sposób wypłacane jest wynagrodzenie za polecenie?",
    answer:
      "Wynagrodzenie wypłacimy na podstawie wystawionej przez Ciebie faktury.",
  },
  {
    question: "Kiedy otrzymam wynagrodzenie za polecenie?",
    answer:
      "Do 10 dni po zakończeniu miesiąca kalendarzowego udostępnimy Ci w formie elektronicznej zestawienie Umów Produktowych zrealizowanych z klientami z Twojego polecenia. Wynagrodzenie wypłacimy w terminie 14 dni od otrzymania od Ciebie faktury.",
  }
]

const formOpened = ref(false);
const breakpoints = useBreakpoints();
const isMobileView = computed(() => breakpoints.lte('lg'));
const openForm = (event) => {
    if(isMobileView.value) {
      event.preventDefault();
      formOpened.value = true;
    } 
}
</script>

<template>
    <div class="sticky top-[97px] md:top-[111px] z-50">
    <Container no-paddings gray>
      <MenuScrolled>
        <AnchorJump>Program partnerski</AnchorJump>
        <AnchorJump to="how-to-start">Jak zacząć</AnchorJump>
        <AnchorJump to="join-us">Dołącz do programu</AnchorJump>
        <AnchorJump to="benefits">Korzyści leasingu</AnchorJump>
        <AnchorJump to="about">O VEHIS</AnchorJump>
        <AnchorJump to="faq">FAQ</AnchorJump>
      </MenuScrolled>
    </Container>
  </div>
  <Container>
    <StaticMainHeader 
        isRpPage
      :h1="'POLECAJ'" 
      :h2="'Samochód na raty'" 
      :heroBoxesData="[
            {
                heading: 'Proste zasady',
                txt: 'Ty polecasz, my zajmujemy się resztą.'
            },
            {
                heading: 'Bez limitu premii',
                txt: 'polecaj bez ograniczeń!'
            },
            {
                heading: 'Bez zobowiązań',
                txt: 'polecasz, kiedy chcesz.'
            }
        ]"
      :text="'Doskonała oferta nabycia samochodu bez działalności gospodarczej.'">
      <template v-slot:mainImage>
        <img class="w-full max-w-[490px]" src="@/assets/images/statics/rp_main.png" alt="" />
      </template>
      <template v-slot:headerDescription>
            <div class="flex gap-4 items-center mt-12 flex-col lg:flex-row">
                <img class="max-w-36 max-h-36" src="@/assets/images/statics/rp-circle-promo.png" alt="" />
                <div>
                    <p class="text-3xl text-coolGray-700">
                        <span class="font-normal">premii</span><span class="font-extralight"> od wartości każdej umowy leasingu z Twojego </span><span class="font-normal">polecenia.</span>
                        <a @click="openForm" href="#contactSection" class="block mt-3 text-azure underline text-xl">Dołącz do programu!</a>
                    </p>
                </div>
            </div>
              <!-- <p class="text-center 2xl:text-left text-2xl lg:text-3xl mt-12 text-coolGray-700 font-normal">
                    Doskonała oferta nabycia samochodu bez działalności gospodarczej.
                </p>   -->
      </template>
    </StaticMainHeader>
  </Container>
  <section id="how-to-start">
    <Container>
      <BlockHowTo
        noSelected
        :negMargin="false"
        textLeft 
        :textCenter="false"  
        :title="'Jak zacząć zarabiać z VEHIS'"
        hideDescription
        :steps="[
            'Zarejestruj się w programie wypełniając formularz. Otrzymasz od nas mail z dalszymi instrukcjami.',
            'Prześlij nam dane polecanego klienta za pomocą formularza dostępnego pod linkiem z maila powitalnego.',
            'Gdy klient podpisze umowę, premia automatycznie zaliczy się na Twoje konto.',
            'Wynagrodzenie otrzymasz za każde skuteczne polecenie.'
            ]"
        :customHeadingClass="'text-3xl text-azure-dark text-center'"
        />
    </Container>
  </section>
    <div id="join-us" class="bg-cool-gray-light hidden lg:block">
      <div class="py-6">
        <FormRpContact analytics-form-type="rp-zarabiaj-1%"  noContainer :customTextBtn="'Wyślij'" title='Dołącz do <p class="text-azure inline-block">programu partnerskiego</p>' standalone />
      </div>
    </div>
    <section id="benefits">
      <StaticOfferHighlight :desktop-img="rpBenefitsDesktop" :mobile-img="rpBenefitsMobile" />
    </section>
    <section id="about">
      <Container noPaddings>
        <StaticAboutVehis />
      </Container>
    </section>
      <StaticCtaPhone/>
      <section id="faq">
        <StaticFaq :faqData="faqData"/>
      </section>
      <section id="contactSection">
        <Container class="2xl:block">
          <FormRpContact analytics-form-type="rp-zarabiaj-1%" title='Dołącz do <p class="text-azure inline-block">programu partnerskiego</p>' standalone />
              <NuxtLink to="/informacje/program-partnerski" class="mt-16 text-center block text-azure text-base font-normal">Regulamin programu partnerskiego PREMIA 1%</NuxtLink>
      </Container>
      </section>
    <div class="bg-cool-gray-light">
      <Container>
        <SeoBrandsCloud noOuterPaddings />
      </Container>
    </div>
        <FormRpContact v-model:opened="formOpened"  widthFull :customTextBtn="'Dołącz do programu partnerskiego'" docking analytics-form-type="rp-zarabiaj-1%" title='Dołącz do <p class="text-azure inline-block">programu partnerskiego</p>' class="lg:hidden"/>
  </template>
  <style>
html {
  scroll-behavior: smooth;

}

#contactSection {
  scroll-margin-top: 150px;
}
</style>