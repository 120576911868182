<script setup>
import texts from '~/configs/texts';
import { useContact } from '~/composables/Contact';

const contact = useContact();
const backend = useBackend();

const props = defineProps({
  title: String,
  standalone: Boolean,
  docking: Boolean,
  car: Object,
  noContainer: Boolean,
  fluid: Boolean,
  source: String,
  analyticsFormType: String,
  additionalData: {
    type: Object,
    default: null
  },
  customTextBtn: {
    type: String,
    default: () => ''
  },
  customFontWeight: {
    type: String,
    default: () => 'normal',
  },
  btnCenter: {
    type: Boolean,
    default: () => false
  },
  opened: {
    type: Boolean,
    default: false
  },
  widthFull: Boolean,
});

const defaultData = {
  name: null,
  phone: null,
  mail: null,
  nip: '',
  companyName: '',
  companyForm: '',
  agr_m: false,
  agr_p: false,
  brand: null,
  model: null,
  body: null,
  product_id: null,
  organization_nip: null,
  endpoint: 'organization-person-create',
  organization_name: null,
  organization_phone: null,
  organization_email: null,
};

const formData = ref({ ...defaultData });
const errors = ref({
  name: null,
  phone: null,
  mail: null,
  nip: null,
  agr_m: null,
  agr_p: null,
  agr_partner: null,
});

const clearForm = () => {
  formData.value = { ...defaultData };
  errors.value = {
    name: null,
    phone: null,
    mail: null,
    nip: null,
    agr_m: null,
    agr_p: null,
  };
};

const mailExpand = ref(false);
const phoneExpand = ref(false);
const partnerExpand = ref(false);

const refStateBlend = ref();
const usedNip = ref('');
const customSuccessTxt = computed(() => {
  if (!usedNip.value) {
    return '';
  }
  return `Za chwilę otrzymasz mail z dalszymi instrukcjami.<br/><br/>Swoich klientów możesz polecać tutaj: <a href="https://vehis.pl/biura-rachunkowe/${usedNip.value}">https://vehis.pl/biura-rachunkowe/${usedNip.value}</a>`;
});
const handleBlur = async () => {
  if (!formData.value.nip) return;
  try {
    const result = await backend.getCompanyData(formData.value.nip);
    if (result?.data?.name) {
      errors.value.nip = "";
      formData.value.companyName = result.data.name;
    } else {
      errors.value.nip = 'Nie znaleziono firmy o podanym NIP';
      formData.value.companyName = '';
    }
  } catch (error) {
    console.error('Błąd podczas pobierania danych firmy:', error);
    errors.value.nip = 'Błąd podczas pobierania danych firmy';
  }
};

const validateAndSend = async () => {
  errors.value = {
    name: null,
    companyName: null,
    phone: null,
    mail: null,
    nip: null,
    agr_m: null,
    agr_p: null,
    agr_partner: false,
  };
  let passed = true;

  if (!formData.value.name || formData.value.name.length < 2) {
    errors.value.name = "Ta wartość jest niepoprawna";
    passed = false;
  }
  if (!formData.value.phone || !validatePhone(formData.value.phone)) {
    errors.value.phone = "Wprowadź poprawny numer telefonu";
    passed = false;
  }
  if (!formData.value.mail || !validateEmail(formData.value.mail)) {
    errors.value.mail = "Wprowadź poprawny adres email";
    passed = false;
  }
  if (!formData.value.nip) {
    errors.value.nip = "Wprowadź NIP";
    passed = false;
  }
  if (!formData.value.companyName) {
    errors.value.companyName = "Dane firmy nie zostały pobrane";
    passed = false;
  }
  if (!formData.value.agr_m) {
    errors.value.agr_m = "To pole jest wymagane";
    passed = false;
  }
  if (!formData.value.agr_p) {
    errors.value.agr_p = "To pole jest wymagane";
    passed = false;
  }
  if (!formData.value.agr_partner) {
    errors.value.agr_partner = "To pole jest wymagane";
    passed = false;
    }

  if (props.car) {
    formData.value.brand = props.car.brandName;
    formData.value.model = props.car.modelName;
    formData.value.body = props.car.bodyType;
    formData.value.product_id = props.car.group_id;
  }
  if (props.additionalData) {
    formData.value.options = props.additionalData;
  }
  if (props.source) {
    formData.value.source = props.source;
  }
  if (props.analyticsFormType) {
    formData.value.formType = props.analyticsFormType;
  }

  if (passed) {
    refStateBlend.value.setBusy();
    usedNip.value = formData.value.nip;
    const result = await contact.submit(formData);
    if (!result) {
      refStateBlend.value.setFailure();
    } else {
      refStateBlend.value.setSuccess();
      clearForm();
    }
  }
};

const opened = ref(false);
const emit = defineEmits(['update:opened']);
watch(
  () => props.opened,
  (newVal) => {
    opened.value = newVal;
  }
);
watch(opened, (newVal) => {
  emit('update:opened', newVal);
});

onMounted(() => {
  if (props.docking) {
    document.documentElement.classList.add('dock');
  }
});
onUnmounted(() => {
  if (props.docking) {
    document.documentElement.classList.remove('dock');
  }
});
</script>

<template>
    <div :class="{
      'rounded-lg': !props.docking && !props.noContainer,
      'overflow-hidden shadow-box bg-white': !props.noContainer,
      'lg:sticky lg:top-36': !props.standalone,
      'lg:max-w-[948px] lg:mx-auto': props.standalone,
      'dock fixed left-0 w-full z-60 lg:relative lg:z-0 rounded-0 lg:rounded-lg': props.docking
    }">
      <!-- Mobilny nagłówek przy docking -->
      <div v-if="props.docking && !opened" class="block lg:hidden bg-white container mx-auto pt-2 pb-4">
        <div :class="props.btnCenter ? 'justify-center' : 'justify-between'" class="flex flex-wrap w-full items-center px-4 sm:px-8 gap-y-1">
          <div class="font-extralight text-sm w-1/2 md:w-1/3" v-if="props.car">
            <div class="whitespace-nowrap overflow-ellipsis overflow-hidden">
              {{ props.car?.brandName }} {{ props.car?.modelName }}
            </div>
            <div class="whitespace-nowrap overflow-ellipsis overflow-hidden">
              {{ props.car?.versionName }}
            </div>
          </div>
          <div class="flex gap-2 w-2/7" v-if="props.car?.installment">
            <div>
              <EffectPrice class="text-azure text-4xl" :value="props.car?.installment" />
            </div>
            <div class="font-extralight text-xs pt-1">
              <div>
                zł
                <EffectNetGrossIndicator />
              </div>
              <div>miesięcznie</div>
            </div>
          </div>
          <div :class="[ 'w-full pt-0 sm:pt-1', { 'md:w-1/3': !props.widthFull, 'flex items-center justify-center': !props.car } ]">
            <ButtonRounded color="azure" @click="opened = true" :widthFull="props.widthFull" :class="{'!w-52': !props.car}">
              {{ props.customTextBtn ? props.customTextBtn : texts.form.order }}
            </ButtonRounded>
          </div>
        </div>
      </div>
  
      <div class="relative">
        <FormStateBlend 
        :customSuccessTxt="customSuccessTxt" 
        ref="refStateBlend" />
        <div
          :class="[
            'px-6 py-3',
            {
              'container mx-auto': props.docking,
              'hidden lg:block': props.docking && !opened,
              'block !z-60': props.docking && opened,
              '!py-6': !props.noContainer
            }
          ]"
        >
          <div class="mb-4 relative">
            <div
              v-if="props.docking"
              class="flex lg:hidden w-10 h-10 justify-center items-center rounded bg-gray-200 absolute right-0 top-0 cursor-pointer transition-opacity z-60"
              @click="opened = false"
            >
              <IconClose class="!w-5 !h-5 stroke-1" />
            </div>
            <template v-if="props.title">
              <div :class="props.customFontWeight" class="px-4 md:px-20 lg:px-4 text-xl flex flex-col md:flex-row md:gap-1 justify-center items-center">
                <div v-html="props.title" class="text-center"></div>
              </div>
            </template>
            <template v-else>
              <div class="text-xl flex flex-col md:flex-row md:gap-1 justify-center items-center" :class="{'lg:flex-col lg:gap-0': !props.standalone}">
                <div>{{ texts.form.title.line_1 }}</div>
                <div class="text-azure">{{ texts.form.title.line_2 }}</div>
              </div>
            </template>
          </div>
  
          <form novalidate>
            <div class="flex-col gap-3 md:grid md:grid-cols-3 md:gap-4 mt-2">
              <InputText class="col-span-1" label="NIP" v-model="formData.nip" :error="errors.nip" @blur="handleBlur" />
              <InputText :customLabelColor="'text-coolGray-300'" class="col-span-2 pointer-events-none text-coolGray-300" disableTab label="Dane Twojej firmy"  :error="errors.companyName" v-model="formData.companyName" readonly />
            </div>
            <div class="flex-col gap-3 md:grid md:grid-cols-3 md:gap-4 mt-2">
              <InputText label="Imię" v-model="formData.name" :error="errors.name" />
              <InputText label="Numer telefonu" type="tel" v-model="formData.phone" :error="errors.phone" />
              <InputText label="E-mail" type="email" v-model="formData.mail" :error="errors.mail" />
            </div>
  


            <div class="mt-8 md:gap-6"
               :class="{'md:grid md:grid-cols-3 md:gap-4':!fluid,'lg:block lg:items-center':!standalone && !fluid, 'block md:block lg:block items-center xl:flex xl:gap-6':fluid} ">
               <InputCheckBox @change="(checked) => formData.agr_partner = checked" :error="errors.agr_partner" :checked="formData.agr_partner">
                <span class="pr-1">{{ texts.form.agreements.nip.small }}</span>
                <ButtonToggle @toggle="(e) => partnerExpand = e">
                  <template #default>{{ texts.form.agreements.nip.more }}</template>
                  <template #toggled>{{ texts.form.agreements.nip.less }}</template>
                </ButtonToggle>
                <Accordion :expanded="partnerExpand">

                  {{ texts.form.agreements.nip.large }}                  
                  <NuxtLink :to="'informacje/program-partnerski'">
                    (link)
                  </NuxtLink>
                </Accordion>
              </InputCheckBox>

              <InputCheckBox @change="(checked) => formData.agr_p = checked" :error="errors.agr_p" :checked="formData.agr_p">
                <span class="pr-1">{{ texts.form.agreements.phone.small }}</span>
                <ButtonToggle @toggle="(e) => phoneExpand = e">
                  <template #default>{{ texts.form.agreements.phone.more }}</template>
                  <template #toggled>{{ texts.form.agreements.phone.less }}</template>
                </ButtonToggle>
                <Accordion :expanded="phoneExpand">
                  {{ texts.form.agreements.phone.large }}
                </Accordion>
              </InputCheckBox>
  
              <InputCheckBox @change="(checked) => formData.agr_m = checked" :error="errors.agr_m" :checked="formData.agr_m">
                <span class="pr-1">{{ texts.form.agreements.mail.small }}</span>
                <ButtonToggle @toggle="(e) => mailExpand = e">
                  <template #default>{{ texts.form.agreements.mail.more }}</template>
                  <template #toggled>{{ texts.form.agreements.mail.less }}</template>
                </ButtonToggle>
                <Accordion :expanded="mailExpand">
                  {{ texts.form.agreements.mail.large }}
                  
                </Accordion>
              </InputCheckBox>
            </div>

            <div class="text-xs text-gray-400 mt-4">
              {{ texts.form.disclaimer.content }}
              <a href="/informacje/polityka-prywatnosci" rel="nofollow" class="underline">
                {{ texts.form.disclaimer.more }}
              </a>
            </div>
  
            <div class="my-4 md:order-2 md:mt-5 md:mb-0 md:mx-auto"
                 :class="{'md:w-[300px]':!fluid, 'w-full':fluid, 'lg:order-1 lg:my-3 lg:w-auto lg:mx-0':!standalone, '!order-1': docking}">
              <ButtonRounded color="azure" @click="validateAndSend">{{customTextBtn ? customTextBtn : texts.form.order }}</ButtonRounded>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  