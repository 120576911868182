<script setup>
const { gte } = useBreakpoints();

const props = defineProps({
  desktopImg: {
    type: String,
    required: true
  },
  mobileImg: {
    type: String,
    required: true
  }
});
</script>

<template>
  <div class="bg-dark-gray w-full mt-8 lg:mt-[112px] mb-[112px]">
    <Container>
      <div class="max-w-[948px] mx-auto text-white">
        <div class="flex mx-auto">
          <img :src="gte('sm') ? props.desktopImg : props.mobileImg" alt="benefits" />
        </div>
      </div>
    </Container>
  </div>
</template>
